<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>单位管理</a-breadcrumb-item>
      <a-breadcrumb-item>其他设置</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">

      <a-form-model ref="batchForm" :label-col="{'span':4}" :wrapper-col="{ 'span':20}">
        <a-form-model-item label="核销方式">
          <a-radio-group v-model="s1" @change="changeCheckType">
            <a-radio-button v-for="item in a1" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="订单详情展示">
          <a-radio-group v-model="s6" @change="setJumpDetail">
            <a-radio-button v-for="item in a6" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="核销语音播报">
          <a-radio-group v-model="s3" @change="setVoiceType">
            <a-radio-button v-for="item in a3" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="!agent_inst" label="微信支付">
          <a-radio-group v-model="s2" @change="changePayStatus">
            <a-radio-button v-for="item in a2" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="余额不足提醒">
          <a-radio-group v-model="s4" @change="setRemindMoney">
            <a-radio-button v-for="item in a4" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="!agent_inst" label="报餐订单备注">
          <a-radio-group v-model="s5" @change="setMark">
            <a-radio-button v-for="item in a5" :value="item.value" :key="item.value">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        
      </a-form-model>
    </div>
    <!-- 修改金额 -->
    <a-modal v-model="showChangeMoney" title="设置充值提醒最低金额" @ok="handleUpdateMoney" @cancel="cancelFn" okText="保存">
      <a-form-model :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="充值提醒最低金额">
          <a-input v-model="money" placeholder="请输入充值提醒最低金额" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getInstInfo,
  getInstProInfo,
  setVoice,
  instConfig,
  editRemindMoney,
  getRemindMoney,
  setJumpDetail
} from "@/request/api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    return {
      s1: "",
      a1: [
        {
          value: "1",
          title: "用户主扫",
        },
        {
          value: "0",
          title: "食堂主扫",
        },
      ],
      s2: "",
      a2: [
        {
          value: "0",
          title: "未启用",
        },
        {
          value: "1",
          title: "已启用",
        },
      ],
      s3: "",
      a3: [
        {
          value: "2",
          title: "简单播报",
        },
        {
          value: "1",
          title: "播报菜品详情",
        },
      ],
      showChangeMoney: false,
      money: 0,
      s4: "",
      a4: [
        {
          value: "2",
          title: "关闭充值提醒",
        },
        {
          value: "1",
          title: "开启充值提醒",
        },
      ],
      agent_inst: false,
      s5: '',
      a5: [
        {
          value: "0",
          title: "关闭",
        },
        {
          value: "1",
          title: "开启",
        }
      ],
      s6: '',
      a6: [
        {
          value: "1",
          title: "是",
        },
        {
          value: "2",
          title: "否",
        }
      ]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    instInfo () {
      getInstInfo().then((res) => {
        this.s1 = String(res.data.check);
        this.s2 = String(res.data.pay_status);
      });
    },
    instProInfo () {
      getInstProInfo().then((res) => {
        this.s3 = String(res.data.voice_type);
        this.s5 = String(res.data.open_remark)
        this.s6 = String(res.data.jump_detail)
      });
    },
    async getRemindMoneyFn () {
      let { data } = await getRemindMoney();
      this.s4 = data.remind_money <= 0 ? '2' : '1';
      this.money = data.remind_money;
    },
    setVoiceType () {
      let p = {
        voice_type: this.s3,
      };
      setVoice(p)
        .then((res) => {
          this.$message.success("操作成功");
        })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    updateInstConfig (p) {
      instConfig(p)
        .then((res) => {
          this.$message.success("操作成功");
        })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
          } else if(err.status == 400 && p.type == 2){
            this.$message.error(err.data.msg);
            this.s2 = "0"
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeCheckType () {
      let p = {
        type: 1,
        mode: this.s1,
      };
      this.updateInstConfig(p);
    },
    changePayStatus () {
      let p = {
        type: 2,
        mode: this.s2,
      };
      if (this.s2 == 1) {
        this.$confirm({
          content: "开启微信支付将无法使用一日多报功能,确定开启？",
          onOk: () => {

            this.updateInstConfig(p);
          },
          onCancel: () => {
            this.s2 = "0"
          },
        });
      } else {
        this.updateInstConfig(p);
      }

    },
    setRemindMoney () {
      if (this.s4 == 2) {
        //关闭
        this.$confirm({
          content: '是否关闭充值提醒?',
          onOk: () => {
            let param = {
              money: 0
            }
            editRemindMoney(param).then(res => {
              this.$message.success('修改成功')
            })
          }
        })

      } else {
        //开启
        this.showChangeMoney = true;
      }
    },

    handleUpdateMoney () {
      if (this.money == '') {
        this.$message.error('请输入提醒金额')
        return
      }
      if (this.money < 0) {
        this.$message.error('金额不能小于0元')
        return
      }
      let param = {
        money: this.money
      }
      this.$confirm({
        content: `是否将充值提醒最低金额设置成${this.money}元?${this.money <= 0 ? '设置成0元将会关闭充值提醒' : ''}`,
        onOk: () => {
          editRemindMoney(param).then(res => {
            this.$message.success('修改成功')
            this.showChangeMoney = false
            this.getRemindMoneyFn()
          })
        },
      })

    },

    cancelFn () {
      this.s4 = '2';
    },

    async setMark(){
      let p = {
        type: 3,
        mode: this.s5
      };
      await this.updateInstConfig(p);
    },

    setJumpDetail(){
      let p = {
        jump_detail: this.s6
      };
      setJumpDetail(p).then(res => {
        this.$message.success("操作成功");
      }).catch(err => {
        if(err.status == 201){
          this.$message.success("操作成功");
        }
      })
      
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.instInfo();
    this.instProInfo();
    this.getRemindMoneyFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
   },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
</style>